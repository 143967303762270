import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { Gender } from '@axl/accel-framework/enums';
import moment from 'moment';

export type SaveStudentModel = {
    avatarId?: string;
    email?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    phone?: string;
    gender?: Gender;
    birthday?: moment.Moment;
    password?: string;
};

export class SaveStudentRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public body = {
        ...this.model
    }
    public url: string = `/student`;

    constructor(public model: SaveStudentModel) {
        super();
    }
}

export class SaveStudentResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}