import { LoadingOverlay, useTimer } from '@axl/accel-framework/components';
import { HubClient } from '@axl/accel-framework/hub-client';
import { TimeSpan } from '@axl/accel-framework/utils';
import { useSettings } from 'components';
import { UserLogoutSubscription } from 'hub-client/subscriptions/UserLogoutSubscription';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useInstance } from 'react-ioc';
import { ServiceStore, UserStore } from 'stores';

type Props = {
}
const UserProvider: React.FC<Props> = ({ children }) => {
    const userStore = useInstance(UserStore);
    const serviceStore = useInstance(ServiceStore);
    const hub = useInstance(HubClient);
    const settings = useSettings();

    const updateTokenTimer = useTimer();

    const [ready, setReady] = useState(userStore.isAuthorized);

    useEffect(() => {
        if (ready) return;
        (async () => {
            // user initialization
            if (!userStore.isAuthenticated)
                // refresh triggers fetchMe by default
                await serviceStore.refreshTokens();
            else
                await serviceStore.updateUser();
            setReady(true);
        })();
    }, []);

    useEffect(() => {
        if (userStore.isAuthorized)
            updateTokenTimer.setInterval(() => serviceStore.refreshTokens(), TimeSpan.fromMinutes(settings.tokenUpdateIntervalMin));
        return () => updateTokenTimer.clear();
    }, [userStore.isAuthorized]);

    useEffect(() => {
        if (!userStore.isAuthorized) return;

        //can't use navigate because UserProvider out of context <Router> component
        const sub = hub.on(new UserLogoutSubscription(userStore.currentUser.session.id), () => document.location.href = '/login?act=logout');
        return () => sub.dispose();
    }, [userStore.isAuthorized]);

    if (!ready) return <LoadingOverlay position='absolute' />;
    return <>{children}</>;
}

export default observer(UserProvider);